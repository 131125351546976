import { DOM } from "@subgraph-io/edge-ui/dom/DOM.js";
import { createPopper, Instance as PopperInstance } from '@popperjs/core';

import { FirebaseApp } from "firebase/app";
import { signInWithEmailAndPassword, signInWithCustomToken, Auth, getRedirectResult, signInWithRedirect, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

import { Session } from "./session.js";
import { API } from "./api.js";

class Signin {

    protected session: Session;
    protected api: API;
    protected firebaseApp: FirebaseApp;
    protected firebaseAuth: Auth;

    protected errorMessage: HTMLElement;

    protected emailInput: HTMLInputElement;
    protected passwordInput: HTMLInputElement;
    protected resetPasswordEmailInput: HTMLInputElement;
    protected resetPasswordMessage: HTMLElement;
    protected resetPasswordError: HTMLElement;

    protected popper?: PopperInstance;
    protected forgotPasswordPopover: HTMLElement;
    protected popoverOverlay: HTMLElement;

    protected forgotPasswordButton: HTMLElement;
    protected signInEmailButton: HTMLElement;
    protected signInGoogleButton: HTMLElement;
    protected signInMicrosoftButton: HTMLElement;
    protected signUpButton: HTMLElement;

    constructor(session: Session) {
        this.session = session;
        this.api = session.getAPI();
        this.firebaseApp = session.getFirebaseApp();
        this.firebaseAuth = session.getFirebaseAuth();

        this.errorMessage = document.getElementById('error-msg') as HTMLElement;

        this.emailInput = document.getElementById('email-input') as HTMLInputElement;
        this.passwordInput = document.getElementById('password-input') as HTMLInputElement;

        this.forgotPasswordButton = document.getElementById('forgot-password-btn') as HTMLElement;

        this.signInEmailButton = document.getElementById('sign-in-email-btn') as HTMLElement;
        this.signInGoogleButton = document.getElementById('sign-in-google-btn') as HTMLElement;
        this.signInMicrosoftButton = document.getElementById('sign-in-microsoft-btn') as HTMLElement;

        this.signUpButton = document.getElementById('signup-btn') as HTMLElement;

        this.emailInput.addEventListener('keyup', this.onSignInInputKeyUp.bind(this));
        this.passwordInput.addEventListener('keyup', this.onSignInInputKeyUp.bind(this));

        const bodyDOM = new DOM(document.body);

        this.popoverOverlay = DOM.createHTMLElement('div');
        this.popoverOverlay.id = 'forgot-password-popover-overlay';
        DOM.setStyles({
            display: 'none',
            background: 'rgba(0, 0, 0, 0)',
            'z-index': 9000,
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }, this.popoverOverlay);
        bodyDOM.append(this.popoverOverlay);

        this.forgotPasswordPopover = DOM.createHTMLElement('div');
        DOM.setStyles({
            display: 'none',
            'box-shadow': '0 0 10px rgba(0, 0, 0, .1)',
            background: 'white',
            border: '1px solid #DDD',
            padding: '20px',
            width: '400px',
            'z-index': 9001
        }, this.forgotPasswordPopover);

        this.forgotPasswordPopover.innerHTML = `
            <div style="text-align: center; margin-bottom: 10px;">Enter your email and we'll send you a password reset link</div>
            <div class="alert alert-danger" id="forgot-password-error" style="text-align: center; margin-bottom: 10px; display: none;"></div>
            <div class="alert alert-success" id="forgot-password-message" style="text-align: center; margin-bottom: 10px; display: none;"></div>
            <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                <input id="reset-password-email-input" class="input100" type="text" name="email" placeholder="Email">
                <span class="focus-input100-1"></span>
                <span class="focus-input100-2"></span>
            </div>
            <div class="container-login100-form-btn m-t-20">
                <input type="submit" id="reset-password-btn" class="login100-form-btn" value="Reset Password">
            </div>
        `;
        bodyDOM.append(this.forgotPasswordPopover);

        this.resetPasswordEmailInput = document.getElementById('reset-password-email-input') as HTMLInputElement;
        this.resetPasswordMessage = document.getElementById('forgot-password-message') as HTMLElement;
        this.resetPasswordError = document.getElementById('forgot-password-error') as HTMLElement;

        this.resetPasswordEmailInput.addEventListener('keyup', this.onResetPasswordInputKeyUp.bind(this));

        bodyDOM.registerForEvent('#forgot-password-popover-overlay', 'click', this.onPopoverOverlayClick, this);
        bodyDOM.registerForEvent('#reset-password-btn', 'click', this.onResetPasswordButtonClick, this);
        bodyDOM.registerForEvent('#forgot-password-btn', 'click', this.onForgotPasswordButtonClick, this);
        bodyDOM.registerForEvent('#sign-in-email-btn', 'click', this.onSignInEmailButtonClick, this);
        bodyDOM.registerForEvent('#sign-in-google-btn', 'click', this.onSignInGoogleButtonClick, this);
        bodyDOM.registerForEvent('#sign-in-microsoft-btn', 'click', this.onSignInMicrosoftButtonClick, this);
        bodyDOM.registerForEvent('#signup-btn', 'click', this.onSignUpButtonClick, this);

        this.handleAuthRedirectResult();
        this.emailInput.focus();

        // let's hit the portal logout endpoint whenever we hit login
        // since there's a chance the session expired and so the endpoint was never hit
        // by an explicit user signout
        this.session.sendPortalSignOut();
    }

    protected async resetPassword() {
        const email = this.resetPasswordEmailInput.value

        this.resetPasswordError.style.display = 'none';

        if (email === '') {
            this.resetPasswordError.style.display = 'block';
            this.resetPasswordError.innerHTML = `Please enter your email`;
            return;
        }

        try {
            const resetPasswordResult = await this.api.resetPassword({email});

            if (resetPasswordResult.ok) {
                this.emailInput.value = email;
                this.resetPasswordMessage.style.display = 'block';
                this.resetPasswordMessage.innerHTML = `Please check your email and use the link sent to change your password`
            }
            else {
                this.resetPasswordError.style.display = 'block';
                this.resetPasswordError.innerHTML = resetPasswordResult.errors[0].message;
            }

        }
        catch (error) {
            console.log(error);
            this.resetPasswordError.style.display = 'block';
            this.resetPasswordError!.innerHTML = 'An unknown error occurred during login. Please try again.';
        }
    }

    protected closeForgotPasswordPopover() {
        this.popper?.destroy();
        this.popper = undefined;
        this.popoverOverlay.style.display = 'none';
        this.forgotPasswordPopover.style.display = 'none';
        this.resetPasswordMessage.style.display = 'none';
        this.resetPasswordEmailInput.value = '';
    }

    protected redirectAfterSignin() {
        const next = this.getNextQueryParam();

        if (next !== undefined) {
            window.location.href = next;
            return;
        }

        window.location.href = '/views/account/main';
    }

    protected async handleAuthRedirectResult() {
        try {
            const result = await getRedirectResult(this.firebaseAuth);

            if (result !== null) {
                this.redirectAfterSignin();
            }
        }
        catch (error) {
            console.error(error);
            // TODO what to do on error? This happens on every page load but only is triggered after an OAuth flow
        }
    }

    protected async signInWithEmailAndPassword() {
        const email = this.emailInput.value;
        const password = this.passwordInput.value;

        this.errorMessage.style.display = 'none';

        if (email === '' || password === '') {
            this.errorMessage.style.display = 'block';
            this.errorMessage!.innerHTML = 'Please enter email and password to log in';
            return;
        }

        this.signInEmailButton.innerHTML = `Logging In...`;

        try {
            const signInResult = await this.api.signIn({email, password});

            if (signInResult.ok) {
                await signInWithCustomToken(this.firebaseAuth, signInResult.token!);
                await this.session.setCookie();
                this.redirectAfterSignin();
            }
            else {
                this.signInEmailButton.innerHTML = `Log in with Email/Password`;
                this.errorMessage.style.display = 'block';
                this.errorMessage!.innerHTML = signInResult.errors[0]?.message;
            }

        }
        catch (error) {
            this.signInEmailButton.innerHTML = `Log in with Email/Password`;
            this.errorMessage.style.display = 'block';
            this.errorMessage!.innerHTML = 'An unknown error occurred during login. Please try again.';
        }

    }

    protected async signInWithGoogle() {
        const provider = new GoogleAuthProvider();

        provider.addScope('profile');
        provider.addScope('email');

        await signInWithRedirect(this.firebaseAuth, provider);
    }

    protected async signInWithMicrosoft() {
        const provider = new OAuthProvider('microsoft.com');

        await signInWithRedirect(this.firebaseAuth, provider);
    }

    protected getNextQueryParam() {
        const params = new URLSearchParams(window.location.search);
        let next;

        for (const [key, value] of params) {
            if (key === 'next') {
                next = value;
            }
        }

        return next;
    }

    protected onSignInInputKeyUp(event: KeyboardEvent) {
        if (event.code === 'Enter') {
            this.signInWithEmailAndPassword();
        }
    }

    protected onSignInEmailButtonClick(event: any) {
        this.signInWithEmailAndPassword();
    }

    protected onSignUpButtonClick(event: Event) {
        const next = this.getNextQueryParam();

        if (next) {
            window.location.href = `/views/auth/signup?next=${encodeURIComponent(next)}`;
        }
        else {
            window.location.href = `/views/auth/signup`;
        }
    }

    protected onSignInGoogleButtonClick() {
        this.signInWithGoogle();
    }

    protected onSignInMicrosoftButtonClick() {
        this.signInWithMicrosoft();
    }

    protected onForgotPasswordButtonClick() {
        this.resetPasswordEmailInput.value = this.emailInput.value;

        if (this.popper === undefined) {
            this.forgotPasswordPopover.style.display = 'block';
            this.popoverOverlay.style.display = 'block';
            this.popper = createPopper(this.forgotPasswordButton, this.forgotPasswordPopover, {
                placement: 'bottom-end',
                modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 0],
                      },
                    },
                  ],
            });
            this.resetPasswordEmailInput.focus();
        }
    }

    protected onResetPasswordInputKeyUp(event: KeyboardEvent) {
        if (event.code === 'Enter') {
            this.resetPassword();
        }
    }

    protected onResetPasswordButtonClick() {
        this.resetPassword();
    }

    protected onPopoverOverlayClick() {
        this.closeForgotPasswordPopover();
    }

}

export function init(session: ConstructorParameters<typeof Signin>[0]): Signin | void {
    try {
        return new Signin(session);
    }
    catch (error) {
        console.error(error);
    }
}